//export const base_url = 'https://api.spotivity.com/spotivityadmin/admin/';         // for staging


// export const base_url_new = 'https://api.spotivity.com/spotivityadmin/admin/';         // for staging
export const base_url_new = 'https://stag.spotivity.com/spotivityadmin/admin/';
export const base_url='https://stag.spotivity.com/spotivityadmin/admin/'

export const base_url_dashboard='https://stag.spotivity.com/spotivityadmin/'


// Login Management
export const login =  base_url_new + 'admin/adminlogin';
export const getUser = base_url + 'admin/allUser';
export const getUserById = base_url + 'admin/user/{id}';
export const getUsers = base_url + 'user/user/';
export const forgotPassword = base_url + 'admin/forgotPassword';
export const resetPass = base_url + 'admin/resetPassword/{token}';
export const getStaticPageById = base_url + 'user/static/pages/{pageId}';
export const createStaticPage = base_url + 'user/static/pages/';
export const getHeroStoryById = base_url + 'user/heroes/{heroId}';
export const createHeroStory = base_url + 'user/heroes/';
export const uploadVideo = base_url + 'user/heros/video/';
export const getParentById = base_url + 'user/parents/{parentId}';
export const getParents = base_url + 'user/parents/';
export const getProgramOwnerById = base_url + 'user/programOwner/{programOwnerId}';
export const getProgramOwners = base_url + 'user/programOwner/';
export const getProgramById = base_url + 'user/program/{programId}';
export const getPrograms = base_url + 'user/program/';
export const createProgram = base_url + 'user/program/create';

